<template>
	<div id="sitelogin">
		<div class="loginbox">
			<div class="titlebox">
				<span>{{shop_name||''}}</span>
				<span v-if="guard_name=='periphery_admin_user'">{{type==1?'站点':'商家'}}</span>
				<span>智家政区域经理控制台</span>
			</div>

			<div class="inputbox" style="margin-bottom: 40px;">
				<div class="label">登录账号</div>
				<el-input type="text" style="input" v-model="user_name" placeholder="请输入账号"></el-input>
			</div>

			<div class="inputbox">
				<div class="label">登录密码</div>
				<el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
			</div>

			<el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
		</div>
	</div>
</template>

<script>
	const ro = [
		{
			"name": "概况",
			"component": "apps/haowu/manager/overview.vue",
			"path": "/haowu/manager/overview",
			"icon": "",
			"layout": "1",
			"router_name": "haowumanageroverview",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": []
		}, 
		{
			"name": "团队",
			"component": "Layout",
			"path": "",
			"icon": "",
			"layout": "1",
			"router_name": "haowumanagerteam",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": [
				{
					"name": "团队管理",
					"component": "apps/haowu/manager/team/index.vue",
					"path": "/haowu/manager/team",
					"icon": "https://mp.mocols.com/storage/upload/file/20240719/20240719172832797.svg",
					"layout": "1",
					"router_name": "haowumanagerteam",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "团队订单",
					"component": "apps/haowu/manager/team/order.vue",
					"path": "/haowu/manager/team/order",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerteam",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "团队阿姨",
					"component": "apps/haowu/manager/team/aunt.vue",
					"path": "/haowu/manager/team/aunt",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerteam",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "团队营收",
					"component": "apps/haowu/manager/team/ying.vue",
					"path": "/haowu/manager/ying",
					"icon": "https://mp.mocols.com/storage/upload/file/20240719/202407191728386330.svg",
					"layout": "1",
					"router_name": "haowumanagerteam",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				}
			]
		},
		{
			"name": "阿姨",
			"component": "apps/haowu/manager/aunt/index.vue",
			"path": "/haowu/manager/aunt",
			"icon": "",
			"layout": "1",
			"router_name": "haowumanageraunt",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": []
		},
		
		
		{
			"name": "订单",
			"component": "Layout",
			"path": "",
			"icon": "",
			"layout": "1",
			"router_name": "haowumanagerarorder",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": [
				{
					"name": "订单管理",
					"component": "apps/haowu/manager/order/index.vue",
					"path": "/haowu/manager/order/order",
					"icon": "https://mp.mocols.com/storage/upload/file/20240520/202405201843405102.svg",
					"layout": "1",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "新增订单",
					"component": "apps/haowu/manager/order/add.vue",
					"path": "/haowu/manager/order/add",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "维权订单",
					"component": "apps/haowu/manager/order/wei.vue",
					"path": "/haowu/manager/wei",
					"icon": "https://mp.mocols.com/storage/upload/file/20240520/202405201843455006.svg",
					"layout": "1",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "订单日志",
					"component": "apps/haowu/manager/order/log.vue",
					"path": "/haowu/manager/log",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "订单详情",
					"component": "apps/haowu/manager/order/details.vue",
					"path": "/haowu/manager/order/details",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				},
				{
					"name": "订单预约",
					"component": "apps/haowu/manager/order/yuyue.vue",
					"path": "/haowu/manager/yuyue",
					"icon": "",
					"layout": "2",
					"router_name": "haowumanagerarorder",
					"vue_name": "",
					"app_level": 0,
					"plugin": null,
					"is_plugin": 0,
					"_child": []
				}
			]
		},
		{
			"name": "阿姨订单",
			"component": "apps/haowu/manager/aunt/order.vue",
			"path": "/haowu/manager/aunt/order",
			"icon": "",
			"layout": "2",
			"router_name": "haowumanageraunt",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": []
		},
		{
			"name": "阿姨详情",
			"component": "apps/haowu/manager/aunt/details.vue",
			"path": "/haowu/manager/aunt/details",
			"icon": "",
			"layout": "2",
			"router_name": "haowumanageraunt",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": []
		},
		{
			"name": "阿姨区域",
			"component": "apps/haowu/manager/aunt/area.vue",
			"path": "/haowu/manager/aunt/area",
			"icon": "",
			"layout": "2",
			"router_name": "haowumanageraunt",
			"vue_name": "",
			"app_level": 0,
			"plugin": null,
			"is_plugin": 0,
			"_child": []
		}
	]
	import {
		eltips,
		getUrlKey
	} from '@/util/util.js';
	import {
		resetRouter
	} from '@/router/index.js'
	// import store from '@/store/index.js'
	export default {
		name: 'sitelogin',
		data() {
			return {
				shop_name: '',
				user_name: '', //用户名
				password: '', //密码
				type: '', //1、站点 2、商家
				guard_name: '', //应用名称
				shop_id: '',
			};
		},
		created() {
			// if (getUrlKey('type')) {
			// 	this.type = getUrlKey('type')
			// }

			// if (getUrlKey('name')) {
			// 	this.shop_name = getUrlKey('name')
			// }

			// if (getUrlKey('shop_id')) {
			// 	this.shop_id = getUrlKey('shop_id')
			// }

			// if (getUrlKey('guard_name')) {
			// 	this.guard_name = getUrlKey('guard_name')
			// }
			sessionStorage.setItem('loginPage', '/arealogin')
		},
		methods: {
			//登录
			login() {
				if (!this.user_name) {
					eltips('请输入用户名', 'error')
					return
				}
				if (!this.password) {
					eltips('请输入密码', 'error')
					return
				}

				let data = {
					// shop_id: this.shop_id,
					username: this.user_name,
					password: this.password
				}
				if (this.guard_name) {
					data.guard_name = this.guard_name
				}
				if (this.type) {
					data.type = this.type
				}

				this.axios.post('/api/house/domain/login/index', data).then(res => {
					if (res.code == 200) {
						sessionStorage.setItem('token', res.data.token)
						sessionStorage.removeItem('role_id')
						sessionStorage.removeItem('pluginroute')
						sessionStorage.removeItem('pluginid')
						// this.getuserinfo()
						sessionStorage.setItem('userinfo', JSON.stringify(res.data.user))
						sessionStorage.setItem('role_id', JSON.stringify(res.data.user.id))
						this.getmeuns()
					} else {
						eltips(res.msg, 'error')
					}
				})
			},
			//获取个人信息
			getuserinfo() {
				// this.axios.get('/api/house/domain/info').then(res => {
				// 	if (res.code == 200) {
				// 		sessionStorage.setItem('userinfo', JSON.stringify(res.data))
				// 		if (res.data.user.unitid == 2) {
				// 			sessionStorage.setItem('role_id', res.data.roles[0].id);
				// 		}
				// 		this.getmeuns()
				// 	}
				// })
			},
			//获取权限菜单
			getmeuns() {
				resetRouter()
				this.$store.commit('routestatus', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				sessionStorage.setItem('routes', JSON.stringify(ro))
				this.$router.replace({
					path: "/haowu/manager/overview"
				})
				// this.axios.get('/api/get_menu').then(res => {
				// 	console.log(res)
				// 	resetRouter()
				// 	this.$store.commit('routestatus', 0)
				// 	sessionStorage.removeItem('routestext')
				// 	sessionStorage.removeItem('routes')
				// 	sessionStorage.setItem('routes', JSON.stringify(res.data))
				// 	this.$router.replace({
				// 		path: "/haowu/manager/overview"
				// 	})
				// })
			},
		}
	};
</script>


<style>
	#sitelogin .el-input__inner {
		border: none !important;
	}

	#sitelogin .el-button {
		padding: 18px 20px !important;
		font-size: 16px;
		font-weight: bold;
	}
</style>
<style scoped lang="less">
	#sitelogin {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-image: linear-gradient(#edf5fe, #e2edfe);
		// background: url('/assets/images/login_bg.png');
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 4px 4px 2px rgba(0, 0, 0, .1);

		.loginbox {
			width: 380px;
			background: #FFFFFF;
			border-radius: 20px;
			padding: 30px;
		}

		.titlebox {
			font-size: 20px;
			color: #1E92FF;
			margin-bottom: 40px;
			font-weight: bold;
		}

		.inputbox {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #DEDEDE;
			padding-bottom: 6px;

			.label {
				width: 80px;
			}

			.input {}
		}

		.loginbtn {
			margin-top: 160px;
			width: 100%;
		}
	}
</style>